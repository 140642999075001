//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import mqtt from 'mqtt';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getDeviceDetailFromLocationObjBySid, getformatedYesterDayDate, getformatedCurrentDate, getUserInfo, skipped, down } from "./utility";
import { toast } from "react-toastify";
import firebase from 'firebase/compat/app';
import 'firebase/messaging';
import moment from "moment";

const firebaseConfig = {
  apiKey: "AIzaSyCfwZYSSGodlGTJi1qWjNhPiGQ2TQvL8ko",
  authDomain: "esskayssystems.firebaseapp.com",
  projectId: "esskayssystems",
  storageBucket: "esskayssystems.appspot.com",
  messagingSenderId: "79025972560",
  appId: "1:79025972560:web:dd5bd40318161337bbf855",
  measurementId: "G-28YB6EJ1DR"
};
if (!firebase?.apps.length) {
  firebase?.initializeApp(firebaseConfig);
} else {
  firebase?.app(); // if already initialized, use that one
}
const messaging = firebase?.messaging?.isSupported() ? firebase?.messaging() : null;

const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  logDet?: any;
  deviceName?: any;
  deviceImeiNumber?: any;
  notificationCount?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData?: any;
  token?: string;
  errorMsg?: string;
  loading?: boolean;
  selectedPath?: any;
  selectedDevice?: any;
  devices?: any;
  modems?: any;
  updatedDevicesObj?: any;
  notificationCount?: number;
  anchorEl?: any;
  deviceData?: any;
  deviceCounter?: any;
  deviceMqttData?: any;
  deviceInfo?: any;
  minFlow?: any;
  maxFlow?: any;
  minSpeed?: any;
  maxSpeed?: any;
  minLoad?: any;
  maxLoad?: any;
  production?: any;
  selectedFlowOption?: any;
  userImg?: any;
  showClearModal?: any;
  otpObj: any;
  clearType: any;
  value?: any;
  chartFlowData?: any;
  chartLabels?: any;
  showLoader?: any;
  open?: any;
  filterObj: any;
  deviceReportsList: any;
  minArray: any;
  maxArray: any;
  chartFlowDataModal: any;
  showLoaderModal: any;
  currentProd: any;
  subDevicesList: any;
  logs: any;
  targetData: any;
  prdPercentage: any;
  showDashboardLoader: any;
  locationWiseDevices: any;
  locationWiseDevicesLoad: any;
  showFlowTPHGraph?: any;
  lineChartLoader?: boolean;
  deviceInfoIntialCall?: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getTargetSettingsApiCallId: any;
  getSelectedFlowRateApiCallId: any;
  getSelectedModalFlowRateApiCallId: any;
  apiGetCounterId: string = "";
  mqttConnectionConnected: boolean = false;
  mqttConnection: any;
  saveFireBaseTokeApiID: any;
  getNotificationCountApiID: any;
  generateOtpApiID: any;
  validateOtpApiCallId: any;
  getDeviceReportsApiId: any;
  getDeviceReportsLoadMoreApiId: any;
  getLogsByDeviceIdApi: any;
  updateTargetLoadApiCall: any;
  getDevicesLocationWise: any;
  locationWiseDevicesLoad: any;
  notifyTargetLoadReachedApi: any;
  notifiedTargetReactedApi: boolean = false; 
  removeFirebaseTokenApiId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.clearData = this.clearData.bind(this);
    this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    let userInfo: any = localStorage.getItem('userInfo');
    userInfo = JSON.parse(userInfo);

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      selectedPath: "",
      // The devices that we are getting from backend via local storage are actually modems (Which can have upto 32 devices connected)
      modems: userInfo?.data?.attributes?.devices ?? [],
      deviceData: { chartData: { labels: [], datasets: [] }, deviceInfo: {} },
      deviceCounter: {},
      deviceMqttData: {},
      devices: [],
      selectedDevice: 'stone stand',
      anchorEl: null,
      updatedDevicesObj: userInfo?.data?.attributes?.devices?.data ?? [],
      minFlow: 0,
      maxFlow: 0,
      minSpeed: 0,
      maxSpeed: 0,
      minLoad: 0,
      maxLoad: 0,
      production: 0,
      selectedFlowOption: "1 hour",
      notificationCount: 0,
      showClearModal: false,
      showFlowTPHGraph: false,
      otpObj: { token: '', otp: '' },
      clearType: '',
      userImg: userInfo?.data?.attributes?.photo,
      chartFlowData: [],
      chartLabels: [],
      showLoader: false,
      filterObj: {
        start_date: '',
        end_date: '',
        startDateValid: true,
        endDateValid: true,
        startDateMsg: '',
        endDateMsg: ''
      },
      deviceReportsList: {
        data: [],
        loader: true,
        page_count: 1,
        current_page: 1
      },

      minArray: [],
      maxArray: [],
      chartFlowDataModal: {},
      showLoaderModal: true,
      currentProd: "",
      subDevicesList: [{ sid_name: "loading...", id: "" }],
      logs: { sid_name: "", loader: true, id: "", start: "", low: "", high: "", stop: "", logsArray:[
        { name:"Event:  Started",date:"" },
        { name: "Event:  Low Flow", date :"" },
        {name: "Event:  High Flow", date: "" },
        {name:"Event:  Stopped", date:""}
      ] },
      targetData: {},
      prdPercentage: 0,
      showDashboardLoader: true,
      locationWiseDevices: [],
      locationWiseDevicesLoad: true,
      lineChartLoader: true,
      deviceInfoIntialCall: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    if (window?.location?.pathname?.split("/")[2] == "DeviceInfo") {
      this.locationWiseDevicesLoad = false
    }

    this.getMqttData();
    this.setState({ selectedPath: window?.location?.pathname?.toLowerCase() })
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getTargetSettingsApiCallId) {
        this.getTargetSettingsApiCall(responseJson);
      } else if (apiRequestCallId === this.apiGetCounterId) {
        this.apiGetCounter(responseJson);
      } else if (apiRequestCallId === this.getNotificationCountApiID) {
        this.getNotificationCountApi(responseJson);
      } else if (apiRequestCallId === this.generateOtpApiID) {
        this.generateOtpApi(responseJson);
      } else if (apiRequestCallId === this.validateOtpApiCallId) {
        this.validateOtpApiCall(responseJson);
      } else if (apiRequestCallId === this.getSelectedFlowRateApiCallId) {
        this.getSelectedFlowRateApiCall(responseJson);
      } else if (apiRequestCallId === this.getSelectedModalFlowRateApiCallId) {
        this.getSelectedModalFlowRateApiCall(responseJson);
      } else if (apiRequestCallId === this.getDeviceReportsApiId) {
        this.getDeviceReportsApi(responseJson, apiRequestCallId);
      } else if (apiRequestCallId === this.getLogsByDeviceIdApi) {
        this.getLogsByDeviceId(responseJson)
      } else if (apiRequestCallId === this.updateTargetLoadApiCall) {
        this.updateTargetLoadApi(responseJson);
      } else if (apiRequestCallId === this.getDevicesLocationWise) {
        this.getDevicesLocationWiseCall(responseJson)
      } else if(apiRequestCallId === this.removeFirebaseTokenApiId){
        this.removeFirebaseTokenApi(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getTargetSettingsApiCall = (responseJson) => {
    if (responseJson.data) {
      if (Object.keys(responseJson.data).length !== 0) {
        const targetObj = responseJson?.data?.attributes;
        this.setState((prevState) => ({ ...prevState, 
          targetData: targetObj,
        }));
      } else {
        toast.error("This Device May be Removed for You");
      }
    } else {
      if (responseJson.message) {
        this.showErrorToast(responseJson);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  apiGetCounter = (responseJson) => {
    if (responseJson.data) {
      this.setState((prevState: any) => ({ ...prevState, deviceCounter: responseJson.data }));
    }
    if (responseJson.message) {
      toast.error("Error! " + responseJson.message);
    }
  }

  getNotificationCountApi = (responseJson) => {
    responseJson?.unread_count && this.setState((prevState) => ({ ...prevState, notificationCount: responseJson?.unread_count || 0 }))
  }

  generateOtpApi = (responseJson) => {
    if (responseJson.meta.token) {
      this.setState({ showClearModal: true, otpObj: { token: responseJson.meta.token, otp: '', }, showLoader: false });
    } else {
      this.setState({ clearType: "", showLoader: false });
      if (responseJson.message) {
        this.showErrorToast(responseJson);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  validateOtpApiCall = (responseJson) => {
    this.setState({ showClearModal: false });

    if (responseJson.message) {
      toast.success(this.state.clearType == "clearData" ? "Clear Data command sent successfully." : "Zero Calibrate command sent successfully.");
      this.publishData();
    } else {
      if (responseJson.errors[0] && responseJson.errors[0].otp) {
        toast.error(responseJson.errors[0].otp);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  getSelectedFlowRateApiCall = (responseJson) => {
    if (responseJson?.data) {
      this.setState((prevState) => ({
        ...prevState,
        chartLabels: responseJson?.data?.map((value: any) => value.time ? value.time : value.date),
        chartFlowData: responseJson?.data?.map((value: any) => value.flow),
        maxArray: responseJson?.data?.map((value: any) => ""),
        minArray: responseJson?.data?.map((value: any) => ""),
        showLoader: false, 
        lineChartLoader: false
      }))
    } else {
      this.setState((prevState) => ({ ...prevState, showLoader: false }))
      if (responseJson.message) {
        this.showErrorToast(responseJson);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  getSelectedModalFlowRateApiCall = (responseJson) => {
    if (responseJson?.data) {
      const labels = responseJson?.data?.map((value: any) => value.time ? value.time : value.date);
      const chartDataModal = responseJson?.data?.map((value: any) => value.flow);

      const lineDataModal = {
        labels,
        datasets: [
          {
            label: 'Dataset 1',
            data: chartDataModal,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 9, 132, 0.5)',
            segment: {
              borderColor: (ctx: any) => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
              borderDash: (ctx: any) => skipped(ctx, [6, 6]),
            },
            spanGaps: true,
            tension: 0.3
          },
          {
            label: 'Min Flow',
            fill: false,
            radius: 0,
            borderColor: 'rgb(0, 0, 0)',
            backgroundColor: 'rgba(0,0, 0, 0)',
            borderDash: [8, 5],
            data: responseJson?.data?.map(() => this.state.targetData.min_flow)
          },
          {
            label: 'Max Flow',
            fill: false,
            radius: 0,
            borderColor: 'rgb(0, 0, 0)',
            backgroundColor: 'rgba(0,0, 0, 0)',
            borderDash: [8, 5],
            data: responseJson?.data?.map(() => this.state.targetData.max_flow)
          }
        ],
      };
      this.setState((prevState) => ({
        ...prevState,
        chartFlowDataModal: lineDataModal,
        showLoaderModal: false
      }))
    } else {
      this.setState((prevState) => ({ ...prevState, showLoaderModal: false }))
      if (responseJson.message) {
        this.showErrorToast(responseJson);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  processDeviceData = (responseJson) => {
    const newData = responseJson.data.map((item: any) => {
      const avg_flow = item?.avg_flow ? item?.avg_flow.toFixed(2) : 0;
      const avg_speed = item?.avg_speed ? item?.avg_speed.toFixed(2) : 0;
      const avg_load = item?.avg_load ? item?.avg_load.toFixed(2) : 0;
      const productionFixed = item?.weight ? item?.weight.toFixed(2) : 0;
      const runTime = item?.run_time ? `${item.run_time}` : "00:00";
      const idleTime = item?.idle_time ? `${item.idle_time}` : "00:00";
      const downTime = item?.down_time ? `${item.down_time}` : "00:00";

      return ({ ...item, avg_flow: avg_flow, avg_speed: avg_speed, avg_load: avg_load, run_time: runTime, idle_time: idleTime, down_time: downTime, weight: productionFixed })
    })

    return newData;
  }

  handleNoDeviceData = (responseJson, dataType) => {
    if (dataType === "loadMore") {
      const oldData = { ...this.state.deviceReportsList };
      oldData.loadMore = false;
      this.setState({ deviceReportsList: oldData });
    } else {
      this.setState({
        deviceReportsList: {
          data: [],
          loader: false,
          page_count: 1,
          current_page: 1
        }
      })
    }
  }


  getDeviceReportsApi = (responseJson, apiRequestCallId) => {
    const dataType = apiRequestCallId === this.getDeviceReportsLoadMoreApiId ? "loadMore" : "default";

    if (responseJson.data) {
      const newData = this.processDeviceData(responseJson);

      if (dataType === "loadMore") {
        const oldData = { ...this.state.deviceReportsList };
        oldData.data = [...oldData.data, ...newData];
        oldData.loadMore = false;
        this.setState({ deviceReportsList: oldData });

      } else {
        this.setState({
          deviceReportsList: {
            data: newData,
            loader: false,
            page_count: responseJson.meta?.page_count || 1,
            current_page: responseJson.meta?.current_page || 1
          }
        })
      }
    } else {
      this.handleNoDeviceData()
     
      if (responseJson.message) {
        this.showErrorToast(responseJson);
      } else {
        toast.error("Something went wrong");
      }
    }
  }

  getLogsByDeviceId = (responseJson) => {
    const logObj = { ...this.state.logs }
    logObj.loader = false;
    if (responseJson && Object.entries(responseJson).length > 0) {
      
        logObj.low_flow = responseJson.low_flow;
        logObj.max_flow = responseJson.max_flow;
        logObj.started = responseJson.started;
        logObj.stoped = responseJson.stoped;

        let logsArray = [
          { name:"Event:  Started",date: responseJson.started },
          { name: "Event:  Low Flow", date: responseJson.low_flow },
          {name: "Event:  High Flow", date: responseJson.max_flow },
          {name:"Event:  Stopped", date: responseJson.stoped }
        ];

        let sortedlogsArray = [...logsArray].sort(
          (a, b) => b.date?.split('/').reverse().join()
            .localeCompare(a.date?.split('/').reverse().join())
        );
         
        logObj.logsArray = sortedlogsArray;
    } else {
      if (responseJson.message) {
        this.showErrorToast(responseJson);
      } else {
        toast.error("Something went wrong");
      }
    }
    this.setState({ logs: logObj });
  }

  updateTargetLoadApi = (responseJson) => {
    if (responseJson.message) {
      this.showErrorToast(responseJson);
    }
    responseJson.status == 200 && setTimeout(() => window.location.reload(), 2000);
  }

  getDevicesLocationWiseCall = (responseJson) => {
    const newData = responseJson.data ? responseJson.data.map((devicesObj: any) => {
      const devicesArr: any = Object.values(devicesObj)[0];
      const onbjKey: any = Object.keys(devicesObj)[0];
      const objVal = devicesArr.map((item: any) => ({ ...item, loading: true }));
      return ({ [onbjKey]: objVal })
    }) : [];
    localStorage.setItem("locationWiseDevices", JSON.stringify(newData));
    this.setState({ locationWiseDevicesLoad: false, locationWiseDevices: newData })
    this.locationWiseDevicesLoad = false;
  }

  removeFirebaseTokenApi = (responseJson) => {
    if (responseJson.message) {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("userId");
      localStorage.removeItem("devicesMqtt");
      localStorage.removeItem("tokenExpire");
      localStorage.removeItem("locationWiseDevices");
      window.location.href = "/EmailAccountLoginBlock";
    }
  }

  updateDeviceInformation = async (prd: any) => {
    const params = window.location.pathname.split("/");
    const deviceObj: any = params ? await getDeviceDetailFromLocationObjBySid(params[3], params[4]) : {};
    const data = prd ? [prd, 100 - prd] : [10]
    const deviceChatObj = {
      labels: [deviceObj?.sid_name],
      datasets: [
        {
          label: 'Speed of Devices',
          data: data,
          backgroundColor: [deviceObj?.sid_color, prd ? "#ffffff" : ""],
          borderColor: [deviceObj?.sid_color],
          borderWidth: 1,
        }
      ]
    }

    this.setState((prevState) => ({ ...prevState, deviceData: { chartData: deviceChatObj, deviceInfo: deviceObj } }));
  }

  getNotificationMsg = () =>
    new Promise((resolve) => {
      messaging.onMessage((payload) => {
        toast.info(`New Notification! ${payload?.notification?.title} - ${payload?.notification?.body}`);
        this.setState((prevState: any) => ({ ...prevState, notificationCount: prevState.notificationCount + 1 }))
        resolve(payload);
      });
    });

  getToken = () => {
    const token = localStorage.getItem('token');
    const tokenExpire: any = localStorage.getItem('tokenExpire');
    const tokenExpireDate = tokenExpire ? new Date(new Date(tokenExpire).getTime() + (24 * 60 * 60 * 1000)) : new Date();
    const newDate = new Date();

    if (newDate > tokenExpireDate) {
      this.handleLogout();
    }
    if (!token) {
      window.location.href = '/';
    }
  }

  saveFireBase = () => {
    let fireBaseToken: any = localStorage.getItem('fireBaseToken');
    if (fireBaseToken) {
      this.saveFirebaseToken(fireBaseToken);
    } else {
      messaging?.getToken({ vapidKey: 'BGUWXMkNdmqVs7h9RtApqAxUME6FLndmIUpf07BVl47ORl3jT6iGvnRKN3zVyiYZ3sxVYVUhq6Kw_JjdbibOUPo' }).then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("fireBaseToken", currentToken);
          this.saveFirebaseToken(currentToken);
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    }
  }

  saveFirebaseToken = (fireBaseToken: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.saveFireBaseTokeApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `notification_token`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const httpBody = {
      "notification_token": fireBaseToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  scrollToBottom = () => {
    const params: any = window?.location?.search;     
    if (params.trim() == "?scroll=bottom") {    
      window?.scrollTo(0, document.body.scrollHeight);   
    }
  }

  filterFieldChange = (e: any) => {
    const filterName = e.target.name
    const filterObj = { ...this.state.filterObj };
    if (filterName == "start_date") {
      filterObj['startDateValid'] = true;
      filterObj['startDateMsg'] = '';
    }
    if (filterName == 'start_date') {
      filterObj['endDateValid'] = true;
      filterObj['endDateMsg'] = '';
    }
    const today = new Date();
    const enteredDate = new Date(e.target.value);
    if (enteredDate <= today) {
      filterObj[filterName] = e.target.value;
    }
    this.setState({ filterObj: filterObj });
  };

  getMqttData = () => {
    const { modems } = this.state;

    if (!this.locationWiseDevicesLoad && !this.mqttConnectionConnected && modems && modems.data && modems.data.length) {
      // this.mqttConnection = mqtt.connect('mqtts://mqtt.esskayconnect.com', {
      //   username: 'user',
      //   password: 'password',
      //   port: 8083,
      // });
      this.mqttConnection = mqtt.connect('mqtts://mqtt-prod.esskayconnect.com', {
        username: 'admin',
        password: configJSON.password,
        port: 8083,
      });
      console.log('connect obj-', this.mqttConnection)

      this.mqttConnection.on('connect', () => console.log('connected to mqtt'));
      this.mqttConnectionConnected = true;
      modems.data.map((item: any, i: number) => {
        item?.attributes?.imei_number && this.mqttConnection.subscribe(item?.attributes?.imei_number + '/event')
        item?.attributes?.device_imei_number && this.mqttConnection.subscribe(item?.attributes?.device_imei_number + '/event')
      });

      this.getMqttMessage();
    }
  }

  getMqttMessage = () => {
    this.mqttConnection.on('message', (topic: any, message: any) => {
      console.log('message from mqtt-', message.toString());

      const { data } = JSON.parse(message.toString());

      if (data?.imei && data?.modbus && data?.modbus.length && data?.modbus[0]?.sid) {
        this.setState({ showDashboardLoader: false })

        const processedData = this.processMQTTData(data);
        
        const params = window.location.pathname.split("/");
        if (params[2] == "DeviceInfo") {   
          if (params[3] == processedData.imei) {     
            this.handleDeviceInfoPageMqttData(processedData, params)
          }
        } else {
          this.handleDashboardPageMqttData(processedData)
        }

      }
    });
  }

  processMQTTData = (data) => {
    const dateDtm = `${data.dtm.substr(0, 4)}/${data.dtm.substr(4, 2)}/${data.dtm.substr(6, 2)} ${data.dtm.substr(8, 2)}:${data.dtm.substr(10, 2)}:${data.dtm.substr(12)}`;
    const dtm = new Date(dateDtm);
    let currentDate = new Date();

    currentDate.setMinutes(currentDate.getMinutes() - 15);
    data.oldData = dtm < currentDate;

    const processedData = this.processMQTTData1(data);

    return processedData;
  }

  processMQTTData1 = (data) => {
    let processedData;

    if (data?.modbus && data?.modbus?.length > 1){
      processedData = this.processMQTTData1Yes(data)
    } else {
      processedData = this.processMQTTData1No(data)
    }

    return processedData;
  }

  processMQTTData1Yes = (data) => {
    data?.modbus.map((deviceItem:any) => {
      if(deviceItem.Load && (Math.sign(deviceItem?.Load) === -1)){               
        deviceItem["oldData"]=true;
      } else if(deviceItem.Flow && (Math.sign(deviceItem.Flow) === -1)){
        deviceItem["oldData"]=true;
      } else{
        deviceItem["oldData"]=false;
      }
      if (deviceItem?.Load && deviceItem?.Load > 1000){
        deviceItem['Load'] = 0;
      }
    })

    return data;
  }

  processMQTTData1No = (data) => {
    if (data?.modbus[0]?.Load && (Math.sign(data?.modbus[0]?.Load) === -1)) {              
      data.oldData = true
    }   
    if(data?.modbus[0]?.Load && data?.modbus[0]?.Load > 1000){
      data.modbus[0].Load = 0;
      
    }
    if (data?.modbus[0]?.Flow && (Math.sign(data?.modbus[0]?.Flow) === -1)){
      data.oldData = true
    }

    return data;
  }

  handleDeviceInfoPageMqttData = (data, params) => {
    if(data.modbus && data.modbus.length > 1 && !data.oldData){
      this.setDeviceInfoPageMqttData(data, params);
    } else if(params[4] == data.modbus[0].sid && !data.oldData) {
      const targetPrd = this.state.targetData.production ? this.state.targetData.production : 0;
      const prdPercentage = data.modbus[0].Total && targetPrd !== 0 ? Math.floor((data.modbus[0].Total / targetPrd) * 100) : 0;

      prdPercentage !== 0 && this.updateDeviceInformation(prdPercentage);
      this.setState((prevState) => ({
        deviceMqttData: data.modbus[0], 
        currentProd: data.modbus[0] ? data.modbus[0].Total : prevState.currentProd,
        prdPercentage: prdPercentage
      }))
    }               
  }

  setDeviceInfoPageMqttData = (data, params) => {
    data.modbus.map((multiDeviceItem:any) => {
      if(params[4] == multiDeviceItem.sid && !multiDeviceItem.oldData){
        const targetPrd = this.state.targetData.production ? this.state.targetData.production : 0;
        const prdPercentage = multiDeviceItem.Total && targetPrd !== 0 ? Math.floor((multiDeviceItem.Total / targetPrd) * 100) : 0;
        prdPercentage !== 0 && this.updateDeviceInformation(prdPercentage);

        this.setState((prevState) => ({
          deviceMqttData: multiDeviceItem, 
          currentProd: multiDeviceItem ? multiDeviceItem.Total : prevState.currentProd,
          prdPercentage: prdPercentage
        }))
        
        return true
      }
    })
  }

  handleDashboardPageMqttData = (data) => {
    let dataChanged = false;
    const locationWiseDevices: any = this.state.locationWiseDevices;
    locationWiseDevices.length > 0 && locationWiseDevices.map((devices: any, i: number) => {

      const devices2: any = Object.values(devices)[0];
      const locationName: any = devices ? Object.keys(devices)[0] : [];

      devices2.map((subDevices: any, j: number) => {
        if (subDevices.imei == data?.imei) {
          if(data?.modbus?.length > 1){
            data?.modbus?.map((dev:any) => {
                if(dev?.sid == subDevices.sub_device.sid){
                  if (!dev?.Flow ) {
                    dev.Flow = 0.00;
                  }    
                  if (!dev?.Speed) {
                    dev.Speed = 0.00;
                  }    
                  if (!dev?.Total) {
                    dev.Total = 0.00;
                  }
                  dev.disconnected = dev.stat == 21;                        
                  locationWiseDevices[i][`${locationName}`][j].loading = false;
                  if(!dev.oldData){
                    locationWiseDevices[i][`${locationName}`][j].mqttData = data;
                  }
                  dataChanged = true; 
                  return true;
                }
                
            });
          }else if(data?.modbus[0]?.sid == subDevices.sub_device.sid){                    
            if (!data?.modbus[0]?.Flow ) {
              data.modbus[0].Flow = 0.00;
            }    
            if (!data?.modbus[0]?.Speed) {
              data.modbus[0].Speed = 0.00;
            }    
            if (!data?.modbus[0]?.Total) {
              data.modbus[0].Total = 0.00;
            }
            data.modbus[0].disconnected = data.modbus[0].stat == 21;
            locationWiseDevices[i][`${locationName}`][j].loading = false;
            if(!data.oldData){
              locationWiseDevices[i][`${locationName}`][j].mqttData = data;
            }
            dataChanged = true;                    
          }
        }
      })
    });
    (dataChanged && locationWiseDevices.length > 0) && this.setState({ locationWiseDevices: locationWiseDevices })
  }

  clearData(clearType: any) {
    this.setState({ clearType: clearType, showLoader: true });
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.generateOtpApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_cfmqttconsumer3/send_email_otp'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  publishData = () => {
    const params = window.location.pathname.split("/");
    if (this.state.clearType == "clearData") {
      this.mqttConnectionConnected && this.mqttConnection.publish(`${params[3]}/command`, `$IPCFG,<DEVCMD: MBWR=${params[4]},6,8,165>`);

    } else if (this.state.clearType == "calibrate") {
      this.mqttConnectionConnected && this.mqttConnection.publish(`${params[3]}/command`, `$IPCFG,<DEVCMD: MBWR=${params[4]},6,58,33>`);
    }
    setTimeout(() => window.location.reload(), 2000)
  }

  showErrorToast = (resObj: any) => {
    if (resObj.message) {
      resObj.status !== 200 ? toast.error(resObj.message) : toast.success(resObj.message);
    }
  }

  openMenu = (event: any) => {
    if(event){
      this.setState({ anchorEl: event.currentTarget })
    }
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  handleOtpChange = (otp: any) => {
    const otpObj = { ...this.state.otpObj };
    otpObj['otp'] = otp || '';
    this.setState({ otpObj: otpObj });
  }

  validateDeviceFilterFields = () => {
    const filObj = { ...this.state.filterObj };
    if (filObj.start_date && filObj.end_date) {
      if (Date.parse(filObj.start_date) < Date.parse(filObj.end_date)) {
        filObj['startDateValid'] = true;
        filObj['startDateMsg'] = '';
        filObj['endDateValid'] = true;
        filObj['endDateMsg'] = '';

        this.setState({
          filterObj: filObj,
          deviceReportsList: {
            data: [],
            loader: true,
            page_count: 1,
            current_page: 1
          }
        })
        this.getDeviceFilteredReports('')
      } else {
        filObj['startDateValid'] = false;
        filObj['startDateMsg'] = 'please enter Valid date';
        this.setState({ filterObj: filObj });
      }
    } else {
      if (filObj.start_date == '' || !filObj.start_date) {
        filObj['startDateValid'] = false;
        filObj['startDateMsg'] = 'please enter Valid date';
      }
      if (filObj.end_date == '' || !filObj.end_date) {
        filObj['endDateValid'] = false;
        filObj['endDateMsg'] = 'please enter Valid date';
      }
      this.setState({ filterObj: filObj })
    }
  };

  resetFilter = () => {
    this.setState({
      filterObj: {
        start_date: '',
        end_date: '',
        startDateValid: true,
        endDateValid: true,
        startDateMsg: '',
        endDateMsg: ''
      },
      deviceReportsList: {
        data: [],
        loader: true,
        page_count: 1,
        current_page: 1
      }
    })
    this.getDeviceFilteredReports('default')

  }

  reportsNextPage = (type: any) => {
    const reportsData = { ...this.state.deviceReportsList };
    reportsData.loadMore = true
    // reportsData.data = [];
    if (type == "next") {
      reportsData.current_page = reportsData.current_page + 1;
    } else {
      reportsData.current_page = parseInt(type);
    }
    this.setState({ deviceReportsList: reportsData });
    this.getDeviceFilteredReports('loadMore');
  }

  getDeviceFilteredReports = async (type: any) => {
    let dataParams: string = "";
    const params = window.location.pathname.split("/");
    const deviceObj: any = params ? await getDeviceDetailFromLocationObjBySid(params[3], params[4]) : {};

    if (type == "default") {
      const startDate = await getformatedYesterDayDate('');
      const endDate = await getformatedCurrentDate('');
      dataParams = `&start_date=${startDate}&end_date=${endDate}&page_no=1`;
      this.setState({
        filterObj: {
          start_date: startDate,
          end_date: endDate,
          startDateValid: true,
          endDateValid: true,
          startDateMsg: '',
          endDateMsg: ''
        },
        deviceReportsList: {
          data: [],
          loader: true,
          page_count: 1,
          current_page: 1
        }
      });
    } else if (type == "loadMore") {
      dataParams = `&start_date=${this.state.filterObj.start_date}&end_date=${this.state.filterObj.end_date}&page_no=${this.state.deviceReportsList.current_page}`;

    } else {
      dataParams = `&start_date=${this.state.filterObj.start_date}&end_date=${this.state.filterObj.end_date}&page_no=1`;
    }

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (type == "loadMore") {
      this.getDeviceReportsLoadMoreApiId = requestMessage.messageId;
    }
    this.getDeviceReportsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `device_detail_report?id=${deviceObj?.id}${dataParams}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleOtpSubmit = () => {
    if (this.state.otpObj.otp !== "") {
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.validateOtpApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        '/bx_block_cfmqttconsumer3/mqtt_otp_confirmations'
      );

      const httpBody = {
        "data": {
          "token": this.state.otpObj.token,
          "otp_code": this.state.otpObj.otp,
        },
      };


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;

    } else {
      const otpObj = { ...this.state.otpObj };
      otpObj['otpErrorMessage'] = "Please enter OTP.";
      this.setState({ otpObj: otpObj });
    }
  };

  handleLogout = () => {
  const header = {
    "Content-Type": configJSON.dashboarContentType,
    token: localStorage.getItem("token")
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.removeFirebaseTokenApiId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `remove_notification_token`
  );
  
  const httpBody = {
    notification_token: localStorage.getItem("fireBaseToken")
  };
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  }

  notifyTargetLoadReached = async () => {
    const params = window.location.pathname.split("/");
    const deviceObj: any = params ? await getDeviceDetailFromLocationObjBySid(params[3], params[4]) : {};
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.notifyTargetLoadReachedApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `target_notify?id=${deviceObj?.id}`
    );

    const httpBody = {};

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getNotificationsUnreadCount = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationCountApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/unread_notification_count'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getTargetSettings = async () => {
    const params = window.location.pathname.split("/");
    const deviceObj: any = params ? await getDeviceDetailFromLocationObjBySid(params[3], params[4]) : {};
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTargetSettingsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `device_target/${deviceObj?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSelectedFlowOption = (e: any, newValue: any) => {
    this.setState({ ...this.state, showLoader: true, lineChartLoader: true, selectedFlowOption: e.target.value })
    this.getSelectedFlowData("")
  }

  getCounterDetails = async (id: any) => {
    let sid: any;
    if (id !== "") {
      sid = id;
    } else {
      const params = window.location.pathname.split("/");
      const device: any = await getDeviceDetailFromLocationObjBySid(params[3], params[4]);
      sid = device?.id;
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCounterId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_cfmqttconsumer3/show_time?id=${sid}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSelectedFlowData = async (id: any) => {
    let sid: any;
    if (id !== "") {
      sid = id;
    } else {
      const params = window.location.pathname.split("/");
      const device: any = await getDeviceDetailFromLocationObjBySid(params[3], params[4]);
      sid = device?.id;
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSelectedFlowRateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `flow_rate?id=${sid}&filter_by=${this.state.selectedFlowOption}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  showFullModalForFlowRate = () => {
    this.setState({ showFlowTPHGraph: true });
    this.getSelectedFlowDataModal('');
  }

  getSelectedFlowDataModal = async (id: any) => {
    let sid: any;
    if (id !== "") {
      sid = id;
    } else {
      const params = window.location.pathname.split("/");
      const device: any = await getDeviceDetailFromLocationObjBySid(params[3], params[4]);
      sid = device?.id;
    }
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSelectedModalFlowRateApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `flow_rate?id=${sid}&count=15&filter_by=${this.state.selectedFlowOption}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleBackDrop = () => {
    this.setState({
      showLoader: false
    })
  }

  exportCSVData = () => {
    const startDate = new Date(this.state.filterObj.start_date);
    const endDate = new Date(this.state.filterObj.end_date);
    const heading = [{
      "Date": "Date",
      "Start Time": "Start Time",
      "End Time": "End Time",
      "Run Time": "Run Time (Hours)",
      "Idle Time": "Idle Time (Hours)",
      "Down Time": "Down Time (Hours)",
      "Average Flow (TPH)": "Average Flow (TPH)",
      "Average Speed": "Average Speed",
      "Average Load": "Average Load",
      "Production in Tons": "Production in Tons"      
    }];
    const fileData = this.state.deviceReportsList.data.map((item: any) => ({
      "Date": item.date,
      "Start Time": item.start_time,
      "End Time": item.end_time,
      "Run Time": item.run_time,
      "Idle Time": item.idle_time,
      "Down Time": item.down_time,
      "Average Flow (TPH)": item.avg_flow,
      "Average Speed": item.avg_speed,
      "Average Load": item.avg_load,
      "Production in Tons": item.weight     
    }));
    const forCSV = [...heading, ...fileData];
    const newCsv = forCSV.map((d: any) => Object.values(d).join(",")).join("\n");
    var data = new Blob([newCsv], { type: 'text/csv' });
    var csvURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `reports${startDate.toLocaleDateString()}to${endDate.toLocaleDateString()}.csv`);
    tempLink.click();
  }

  addFooters = (doc: any) => {
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont('helvetica', 'italic')
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.text(`Esskay Systems | Printed on: ${new Date().toDateString()} ${new Date().toLocaleTimeString()}`, doc.internal.pageSize.width / 2, 287, {
        align: 'center'
      })
    }
  }

  exportPDFData = async () => {
    let deviceDetails: any = localStorage.getItem('userInfo');
    deviceDetails = localStorage.getItem('userInfo') ? JSON.parse(deviceDetails)?.data?.attributes?.devices?.data : [];
    const getDeviceName = () => {
      let subDevicesDetails: any;
      deviceDetails.map((item: any) => (item.attributes.device_imei_number == window.location.pathname.split("/")[3]) &&
        item.attributes.sub_devices.map((subItem: any) => subItem.sid == window.location.pathname.split("/")[4] ? subDevicesDetails = subItem.sid_name : 0));
      return subDevicesDetails;
    };

    const deviceSidName = getDeviceName();

    let startDate:any = new Date(this.state.filterObj.start_date);
    let endDate:any = new Date(this.state.filterObj.end_date);
    startDate = moment(startDate).format("D/MM/YYYY HH:mm");
    endDate = moment(endDate).format("D/MM/YYYY HH:mm");
    const eskeyImage = require("../assets/EsskaySystemsLogo.png");
    const custmerName = await getUserInfo("userName");
    const heading = ["Date", "Start Time", "End Time", "Run Time (Hours)", "Idle Time (Hours)", "Down Time (Hours)", "Average Flow (TPH)", "Average Speed", "Average Load", "Production in Tons"];
    const doc: any = new jsPDF();
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    const tableBody = this.state.deviceReportsList.data.map((item: any) => {
      return ([item.date, item.start_time, item.end_time, item.run_time, item.idle_time, item.down_time, item.avg_flow, item.avg_speed, item.avg_load, item.weight])
    });
  
    let docPageNumber: any = 1
    autoTable(doc, {
      head: [heading],
      body: [...tableBody],
      margin: { top: 55, left: 7, right: 7 },
      styles: {
        minCellHeight: 11,
        halign: "center",
        valign: "top",
        fontSize: 8,
        padding: 0,
        margin: 0
      },
      headerStyles: {
        cellPadding: 1,
        lineWidth: 0,
        valign: 'middle',
        fontStyle: 'bold',
        halign: 'center',
        fontSize: 9,
      },
      didDrawPage: function (data: any) {
        // Header
        // doc.page = 1;
        data.settings.margin.top = 15
        if (docPageNumber == 1) {
          doc.addImage(eskeyImage, 'JPEG', 5, 5, 75, 30);
          doc.setFontSize(10);
          doc.text(`From Date`, 127, 10, { baseline: 'top' });
          doc.text(`:  ${startDate}`, 155, 10, { baseline: 'top' });
          doc.text(`To Date`, 127, 16, { baseline: 'top' });
          doc.text(`:  ${endDate}`, 155, 16, { baseline: 'top' });
          doc.text(`Customer Name`, 127, 22, { baseline: 'top' });
          doc.text(`:  ${custmerName}`, 155, 22, { baseline: 'top' });
          doc.text(`Device Name`, 127, 28, { baseline: 'top' });
          doc.text(`:  ${deviceSidName}`, 155, 28, { baseline: 'top' });
          doc.setFontSize(22);
          doc.setFont(undefined, 'bold')
          doc.text("PRODUCTION REPORTS", pageWidth / 2, 50, {align: 'center'});
        
        } else {

        }

        docPageNumber = 2
      },
    });
    this.addFooters(doc);
    doc.save(`reports${startDate}to${endDate}.pdf`);
    return false;
  }

  getLogDeviceList = async () => {
    const subDevicesList = await getUserInfo('subDevicesList');
    const logObj = { ...this.state.logs };
    logObj.id = subDevicesList[0].id;
    logObj.sid_name = subDevicesList[0].sid_name;
    this.setState({ subDevicesList: subDevicesList, logs: logObj });
    subDevicesList.length > 0 && this.getLogDetails(subDevicesList[0].id);
  }

  handleSelectedDeviceChange = (e: any, val: any) => {
    const logObj = { ...this.state.logs }
    logObj.loader = true;
    logObj.id = val.props.value;
    logObj.sid_name = val.props.children;
    this.setState({ logs: logObj });
    this.getLogDetails(val.props.value);
  }

  getLogDetails = (val: any) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLogsByDeviceIdApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/show_logs?id=${val}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  validateTargetData = () => {
    const sub_device= { ...this.state.targetData };
    let errorTargetData = false
    if(sub_device?.min_flow > sub_device?.max_flow){
      sub_device['error_min_flow']=true;
      errorTargetData = true;
    }
    if(sub_device?.min_speed > sub_device?.max_speed){
      sub_device['error_min_speed']=true
      errorTargetData = true;
    }
    if(sub_device?.min_load > sub_device?.max_load){
      sub_device['error_min_load']=true
      errorTargetData = true;
    }
    if(errorTargetData){
      this.setState({targetData : sub_device})
    }else{
      this.updateTagetLoad();
    }
  }

  updateTagetLoad = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.updateTargetLoadApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `set_target/${this.state.targetData.id}/`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const httpBody = {
      sub_device: { ...this.state.targetData },
    };

    delete httpBody.sub_device.id;
    delete httpBody.sub_device.imei_number;
    delete httpBody.sub_device.sid;
    delete httpBody.sub_device.sid_color;
    delete httpBody.sub_device.sid_name;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDashboardBackDrop = () => {
    this.setState({ showDashboardLoader: false })
  }

  getDeviceDetailsLocationWise = () => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDevicesLocationWise = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/account_block/devices'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
